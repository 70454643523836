<template>
  <div>
    <Card class="w-full h-full" shadow>
      <div class="pb-2 md:pb-4mx-auto">
        <div class="">
          <h4
            class="text-grey hidden md:block font-bold md:text-xl text-xl text-center md:text-left"
          >
            Enter OTP
          </h4>
          <p class="text-sm text-grey">
            We sent an OTP to {{ activeUser.email }}.
          </p>
          <form @submit.prevent="submitOtp">
            <OtpInput
              class="mt-8"
              @on-change="handleOnChange"
              @on-complete="handleOnChange"
            />
            <Spinner v-if="verifyloading" />
            <div class="mt-4 flex" v-else>
              <p
                class="underline text-sm text-brandPurple cursor-pointer"
                v-if="showResendButton"
                @click="resendOtp"
              >
                Resend OTP
              </p>
              <div class="ml-auto" v-else>
                <vue-countdown
                  :time="60 * 1000"
                  v-slot="{ minutes, seconds }"
                  @end="showResendButton = true"
                >
                  <span class="text-sm text-grey"
                    >{{ minutes }}:{{ seconds }}</span
                  >
                </vue-countdown>
              </div>

              <!-- </div> -->
            </div>
            <div class="mt-8 bg-lightGrey-1 p-2 flex items-center">
              <svg
                class="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                  fill="#0EA2F5"
                />
              </svg>
              <p class="text-sm">
                If you don’t see our email in your inbox, please check your spam folder
              </p>
            </div>
            <Button
              :disabled="!rule"
              text="Verify email"
              width="w-full"
              class="mt-4"
              shadow
              type="submit"
              :loading="loading"
            />
          </form>
        </div>
      </div>
    </Card>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { Input, OtpInput } from "@/UI/Input";
  import { SecondaryButton, Button } from "@/UI/Button";
  import Spinner from "@/UI/Spinner";
  import VueCountdown from "@chenfengyuan/vue-countdown";

  export default {
    components: {
      Input,
      Button,
      Spinner,
      SecondaryButton,
      OtpInput,
      "vue-countdown": VueCountdown,
    },
    data: () => ({
      tab: 2,
      loading: false,
      verifyloading: false,
      showResendButton: false,
      user: {
        otp: "",
      },
      valid: {
        otp: false,
      },
    }),
    computed: {
      ...mapState({
        registrationId: (state) => state?.auth?.registrationId,
        activeUser: (state) => state?.auth?.user,
      }),
      rule() {
        return this.user.otp.length === 6;
      },
    },
    mounted() {
      this.handleDelayOtpButton();
    },
    methods: {
      ...mapActions("auth", ["register"]),
      ...mapActions("notification", ["showToast"]),
      resendOtp() {
        this.user.email = this.activeUser.email;
        this.verifyloading = true;
        this.register(this.user)
          .then(() => {
            this.verifyloading = false;
            this.showToast({
              display: true,
              icon: "success",
              description: "A new otp has been sent to your email",
            });
            this.showResendButton = false;
            this.handleDelayOtpButton();
          })
          .catch(() => {
            this.verifyloading = false;
          });
      },
      handleDelayOtpButton() {
        setTimeout(() => {
          this.showResendButton = true;
        }, 60000);
      },
      handleOnChange(value) {
        this.user.otp = value;
      },
      submitOtp() {
        this.loading = true;
        this.$store.commit("auth/SET_REGISTRATION_OTP", this.user.otp);
        setTimeout(() => {
          this.loading = false;
          this.$router.push("/password");
        }, 1500);
        if (process.env?.VUE_APP_ENVIRONMENT?.toString() === "production") {
          sendinblue.track('account_created', {
            email: `${this.activeUser.email}`
          });
        }
      },
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
  .main-body {
    min-height: 100vh;
    /* min-height: -webkit-fill-available; */
    display: grid;
    place-items: center;
  }
  @media (max-width: 768px) {
    .main-body {
      min-height: calc(100vh - 10rem);
    }
  }
  .tab-count div {
    background: linear-gradient(0deg, #c70039, #c70039),
      linear-gradient(0deg, #c70039, #c70039),
      linear-gradient(0deg, #c70039, #c70039),
      linear-gradient(0deg, #c70039, #c70039),
      linear-gradient(0deg, #c70039, #c70039), #c70039;
    opacity: 0.1;
  }
  .active {
    opacity: 1 !important;
  }
  .otp-button {
    position: absolute;
    right: 0;
    top: 0.2rem;
    z-index: 5;
  }
</style>
