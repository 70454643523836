<template>
  <Otp />
</template>
<script>
  import Otp from "@/components/Auth/Otp";
  export default {
    components: {
      Otp,
    },
  };
</script>
